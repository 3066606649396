/*Log Page DESIGN*/
body.logscreen{/* background:url(../images/pattern-bg.png) repeat 0 0;*/ background-color: $themeColor; }

.wrapper-log{display:table; @include size (100%)
.logo{img{ margin-left:auto; margin-right:auto; max-width:100%; height:auto;}}
	.ValidationErrors{ position:absolute; font-size:0.68rem; }
	a{ color:$log-link-color;
		&:hover{  text-decoration:none; color: darken($log-link-color, 10%) }
	}
	.inverse-text{color:lighten($themeColor, 100%);}
	a.inverse-text{
		&:hover{color:lighten($themeColor, 50%);}
	}
	.text-link{color:lighten($themeColor, 40%);}
	
	.btn-primary { position:relative; min-height:50px; cursor:pointer;}

	 @include media-breakpoint-down(xs){
		 h4{font-size:1.4rem;}
		 h6 {font-size: 0.9rem; line-height: normal;}
	 }
	
	.form-login{
	.form-group.has-danger{ margin-bottom:0px;}
	}
	.form-group{@include transition(top, 0.5s);  margin-bottom:0px;
		&.has-danger{ margin-bottom:8px;}
	}
	.error-msg, .success-msg{ position:absolute; color:#fdbdbd; font-size:0.75rem; left:0; right:0; text-align:center;}
	.success-msg{color:#a8f6a2;}
	.wrapper-log-inner{display:table-cell; vertical-align:middle;}

 }
.log-block{ margin:($spacer-y * 1.5) 0;}

.log-block label.stay_logged{ color:lighten($themeColor, 100%); margin:0;}

.log-header{display:block; position: relative; overflow:hidden; text-align:center; min-height:2rem;}
.log-heading{ display:inline-block; font-size: $font-size-h3; font-family:$font-family-light; margin:0; position: relative; color:lighten($themeColor, 100%);  font-weight:300;}
.log-heading.heding-md{font-size: $font-size-h4;}
.log-heading:before,
.log-heading:after{ content:''; width:300px; height:1px;  background: rgba(255, 255, 255, 0.2); position:absolute; top:50%;} 
.log-heading:before{ left:-310px;}
.log-heading:after{ right:-310px;}

.floated-label {
	@include transition(top, 0.5s);
	
	display: block;
	padding:0 $input-padding-x ; 
 	color:lighten($gray, 5%);  min-height:2rem;  position:relative; top:45px; z-index:100;
}


.floated-input {outline: medium none;}
.log-block .ValidationErrors{ color:#fdbdbd  /*lighten($text-red, 15%)*/;}

.floated-group {position: relative; margin:0;}

.floated-group:after, .floated-group:before {
	@include transition(); 
	@include size (100%, 2px);
	content: "";
	position: absolute;
	display: block;
	background-color: transparent; 
	bottom:0px;
	left: 0; z-index: 100; 
	}

.floated-group::after {background-color:darken($themeColor, 2%); /*@include transform(scaleX(0));*/ transform: scaleX(0); -webkit-transform: scaleX(0); -moz-transform: scaleX(0); -ms-transform: scaleX(0); -o-transform: scaleX(0);} 
.is-active::after { /*@include transform(scaleX(1)); */ transform: scaleX(1); -webkit-transform: scaleX(1); -moz-transform: scaleX(1); -ms-transform: scaleX(1); -o-transform: scaleX(1);}
.is-active .floated-label, .is-completed .floated-label {color: #fff; padding:0; }
.is-completed .floated-label {font-size:0.75rem; top:20px; }


.log-block input[type="checkbox"]{display:none;}
.log-block input[type="checkbox"]+label{ padding:0;}
.log-block input[type="checkbox"]+label:before{content:"\f096"; width:22px; display:inline-block; font-size:$font-size-lg; vertical-align:middle; font-family:FontAwesome;}
.log-block input[type="checkbox"]:checked+label:before{content:"\f046";}


.log-foot {@extend .log-header;
	.log-foot-text{@extend .log-heading; color:$log-link-color; font-size:$font-size-sm;  
		a{color:lighten($themeColor, 100%);
	 		&:hover{color:lighten($themeColor, 50%);}
		}
	}
	.log-foot-text:before{ @extend .log-heading:before;}
	.log-foot-text:after{ @extend .log-heading:after;}

}

.form-group .help-desk{ position:absolute;}
.help-desk{ font-size:$font-size-xs;}

.form-control:focus{ border-color:lighten($themeColor, 30%)}
.floated-group.input-group { margin-top:($spacer-y * 2.5);
			.floated-label { position:absolute; top:6px}
}
.floated-group.input-group.is-completed .floated-label{top:-20px;}
.input-group-addon{ font-size:inherit;}

.custom-input-group{ margin-top:($spacer-y * 2);
.floated-label { position:absolute; top:6px}
&.is-completed{ .floated-label{top:-20px;}}
	@include media-breakpoint-down(xs){display: block; float:left;    width: 100%;
			.input-group{ display:block;}
			 .input-group-addon, input{
								display:block; 
								border-radius: 0.25rem !important;
								width: 100%; 
								margin-top:5px !important;
								float:left;}
								
					.input-group-addon{ border: 1px solid #cccccc !important;}
					.floated-label{ top: 44px;}
					
	.is-completed {
						.floated-label{ }
					}
	&::after, &:before{bottom: 31px;}
	
	
	}

}

.log-footer{ border-top:1px solid darken(($themeColor), 8%);  padding:$spacer-y/2 0 0;
 ul{ text-align:center; border-top:1px solid lighten(($themeColor), 8%); padding:1.5rem 0; margin:0;
	 li{ display:inline-block; margin:0  0 0 $spacer-x; color:$log-link-color;
	 @include media-breakpoint-down(sm) { margin-left:0; font-size:0.75rem;}
		 a{ color:$log-link-color;  margin:0 $spacer-x 0 0; display:inline-block;
		 
		 @include media-breakpoint-down(sm) {margin-right:0.3rem;}
		 
		 }
		 
		}
	}
	 
}

.company_selection{ list-style: none; margin:$spacer-y 0 0 0; padding: 0; text-align: center; min-height:15rem;

	li{ display:block; margin-bottom:$spacer-y/2;
	
	a{ display:block; font-size: 1.2rem; border: $border-width  solid rgba(255, 255, 255, 0.2); padding:$spacer-x/2; @include transition(box-shadow, 0.5s); box-shadow:0px 0px 0px rgba(0, 0, 0, 0.3); -moz-box-shadow:0px 0px 0px rgba(0, 0, 0, 0.3);  -webkit-box-shadow:0px 0px 0px rgba(0, 0, 0, 0.3);
	
		&:hover{box-shadow:0px 0px 10px rgba(0, 0, 0, 0.3); -moz-box-shadow:0px 0px 10px rgba(0, 0, 0, 0.3);  -webkit-box-shadow:0px 0px 10px rgba(0, 0, 0, 0.3);}
		}
	}
}