// Core variables and mixins
@import "node_modules/bootstrap-sass/scss/variables";
@import "node_modules/bootstrap-sass/scss/mixins";

// Reset and dependencies
@import "node_modules/bootstrap-sass/scss/normalize";


// Core CSS
@import "node_modules/bootstrap-sass/scss/reboot";
@import "node_modules/bootstrap-sass/scss/grid";
// Components


// Components w/ JavaScript

// Utility classes
@import "node_modules/bootstrap-sass/scss/utilities";
@import "fontawesome/font-awesome-outer"; 



@import "function";
@import "variables";
@import "css3-mixins";
//@import "extend";
@import "login_and_register";
@import "themes_login"; 
@import "image";
@import "bootstrap-4";

/*BOOTSTRAP VARIABLE CUSTOMIZATION*/

    



/*BOOTSTRAP MIXIN CUSTOMIZATION*/








/*Global BOOTSTRAP CUSTOMIZATION*/
body {  font-size:$body-font-size; @include size (100%);  font-family:$font-family; }
html { @include size (100%) }
*:focus{outline:0;}


.center-block{ float:none}

.loader{position:absolute; left:0; top:0; right:0; bottom:0; margin:auto; }
.loader img{ max-width:100%; height:auto; display:block; margin:0 auto;}
.loader-sm{@extend .loader; width:2rem; height:2rem;}



.p-y-1 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
}

.m-b-2 {
    margin-bottom: 1.5rem !important;
}
.m-b-0 {
    margin-bottom: 0 !important;
}
.m-t-1 {
    margin-top: 1rem !important;
}
.m-t-2 {
    margin-top: 1.5rem !important;
}
.m-t-5{ margin-top: 5px !important;}
.m-t-3 {
    margin-top: 3rem !important;
}
.p-r-0 {
    padding-right: 0 !important;
}

.btn {
  display: inline-block;
  font-weight: $btn-font-weight;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  font-family:$font-family-light;
  border: $input-btn-border-width solid transparent;
  @include button-size($btn-padding-y-lg, $btn-padding-x-lg, $font-size-lg, $line-height-lg, $btn-border-radius-lg);
  @include transition(all .2s ease-in-out);

  &,
  &:active {
    &:focus {
     @include box-shadow(none);
    }
  }

  @include hover-focus {
    text-decoration: none;
  }
 
  &:active {
    background-image: none;
    outline: 0;
	@include box-shadow(none);
    //@include box-shadow(inset 0 3px 5px rgba(0,0,0,.125));
  }

  &.disabled {
    cursor: $cursor-disabled;
    opacity: .65;
    @include box-shadow(none);
  }
}


.btn-block {
  display: block;
  width: 100%;
}


// Specificity overrides
input[type="submit"],
input[type="button"] {
  &.btn-block {
    width: 100%;
  }
}

.form-control {
  display: block;
  width: 100%;
  // // Make inputs at least the height of their button counterpart (base line-height + padding + border)
  // height: $input-height;
  padding: $input-padding-y $input-padding-x;
  font-size: $font-size-base;
  line-height: $line-height;
  color: $input-color;
  background-color: $input-bg;
  // Reset unusual Firefox-on-Android default style; see https://github.com/necolas/normalize.css/issues/214.
  background-image: none;
  border: $input-btn-border-width solid $input-border-color;
  // Note: This has no effect on <select>s in some browsers, due to the limited stylability of `<select>`s in CSS.
  @include border-radius($input-border-radius);
  @include box-shadow($input-box-shadow);
  @include transition(border-color ease-in-out .15s, box-shadow ease-in-out .15s);


  // Unstyle the caret on `<select>`s in IE10+.
  &::-ms-expand {
    background-color: transparent;
    border: 0;
  }

  // Customize the `:focus` state to imitate native WebKit styles.
  @include form-control-focus();


}


.require, .ValidationErrors, .error-msg{color:red}
.success-msg{color:green;}

.form-group{ margin-bottom:1.2rem;
	.ValidationErrors, .load-check{font-size:0.68rem; position:absolute; bottom:-18px;
		p{ margin:0;}
	}
	.ValidationErrors{
		p{font-size:0.68rem;}
	}
	.chekbox{ padding-top:5px;}
}

.btn:focus{outline:0 important;  outline-offset: 0 important;  }

.btn-primary { position:relative;
	@include button-variant(#fff, lighten($themeColor, 1%), darken($themeColor, 10%));
	&:hover		{@include button-variant(#fff, lighten($themeColor, 5%), darken($themeColor, 5%));}
	&:active	{@include button-variant(#fff, lighten($themeColor, 5%), darken($themeColor, 5%));}
	&:focus		{@include button-variant(#fff, lighten($themeColor, 5%), darken($themeColor, 5%));}
}
.btn-primary:after, .btn-primary:before {
	@include transition(0.5s); 
	@include size (100%, 100%);
	content: "";
	position: absolute;
	display: block;
	background-color: transparent; 
	bottom:0px;
	left: 0; z-index:100;
	}

.btn-primary::after {background-color: rgba(255, 255, 255, 0.03); transform: scaleX(0);}

.btn-primary{
	&:focus::after {transform: scaleX(1); }
}

.form-control { min-height:2rem; font-size:$font-size-sm;   -webkit-appearance: none;
    -moz-appearance: none;
	-o-appearance: none;
	  -ms-appearance: none;
    appearance:none; text-overflow: ellipsis; padding: 0.275rem 0.75rem;

  &:focus {
    border-color:lighten($themeColor,30%);
	
  }

}

.has-danger .form-control, .has-danger .form-control-sm, .has-danger input.show-text {
	  border-color: #d9534f !important;
	}


.grecaptcha-badge {display: none;} 

.browser-version{ background: #faffbd;
      
    text-align: center;
    max-width:580px;
    margin: 0 auto;
    border-radius: 5px;
    padding: 15px 15px 20px;
    margin: 50px auto;
    font-size: 15px;
	
	}
	.browser-version p{ margin-bottom:5px;}
.browser-version strong{ font-size:17px; font-weight:600;}


