// Helper theme mixin
// @param $name: name of the theme (HTML class)
// @param $color: color of the theme

$themes: (
  default: #60c0b7/*5dc1b8*/,
  green_leaf:#185946/*75b540*/,
  green_dark:#4CA669/*#4ca64c*/,

  blue_light:#0D82BE/*0d83dd*/,
  royal_blue : #336699,
  navy_blue: #171A46/*#171a32*/,
  purple_dark: #525279,

  red_light: #5e0030/*#d50f25*/ ,
  red_dark: #9f1010,
  red_night:#472323,
  orange_light: #ff8b00/*ffae19*/, 
  golden_brown: #cfa130/*ce9120*/ ,
  dark_brown: #876b4e,
  gray_dark: #666,
  dark_night: #444,
);
 


@mixin themeouter($name, $color) {

  .#{$name} {

/*__________________________________________________________________________________________________________________________________________________________________________________
  
GLOBAL STYLE
__________________________________________________________________________________________________________________________________________________________*/
 a{color:$color; 	@include transition(color, 0.5s);} 
 .tool-loader .loader-icon i{color:$color; } 

  
.btn-primary {
	@include button-variant(#fff, darken($color, 10%), darken($color, 15%));
	&:hover, &:active, &:focus	{@include button-variant(#fff, lighten($color, 10%), $color);}
}
.btn-default{
	@include button-variant(darken($gray-dark, 30%), $gray-lighter, darken($gray-lighter, 20%));}

.form-control:focus{  border-color: lighten($color, 20%)} 


.theme-color{color:$color !important;} 
	.theme-bg{
		@include button-variant(#fff, darken($color, 10%), darken($color, 15%));
		color:		$white !important;
		&:hover		{@include button-variant(#fff, lighten($color, 10%), $color);}
	}


/*__________________________________________________________________________________________________________________________________________________________________________________
  
GLOBAL SECTION HEADER FOOTER ETC...
__________________________________________________________________________________________________________________________________________________________*/
  .text-link{color:lighten($color, 20%);}
  .wrapper{ background-color:$color;}
  
/*__________________________________________________________________________________________________________________________________________________________________________________
  
START LOGIN PAGE
__________________________________________________________________________________________________________________________________________________________*/
&.logscreen {

$log-link-color:lighten($color, 20%);
$btn-color: darken($color, 18%);


    background-color:darken($color, 15%);
	.wrapper-log a{color:$log-link-color;
		&:hover{color: darken($log-link-color, 10%);}
		
		&.text-link{color:lighten($color, 20%);}

	}
	
	.log-foot{  
		.log-foot-text{color:$log-link-color;
			a{color:lighten($color, 100%);
				&:hover{color:lighten($color,30%);}
			}
		}
	}
	.log-footer{border-color: darken($color, 20%);
		ul{border-color: darken($color, 10%);
			li{ color:$log-link-color;
			a{color:$log-link-color;}
			}
		}
	}
	
		.btn-primary { position:relative; min-height:50px;
			@include button-variant(#fff, $btn-color, darken($btn-color, 5%));
			&:hover, &:active, &:focus{@include button-variant(#fff, lighten($btn-color, 11%), lighten($btn-color, 8%));}
		}

	.floated-group::after{background-color:darken($color, 20%);}
	.form-sign-up-steps .card.invitation-form {
		.card-header{background-color:darken($color, 16%); border-color:darken($color, 19%);}
		.is-completed .floated-label{color:$color; }
	}
}



/*__________________________________________________________________________________________________________________________________________________________________________________
  
CONDITION FOR THEME DESIGN
__________________________________________________________________________________________________________________________________________________________*/
 
   @if $name == 'default'{
	
		/*_LOGIN _*/

		&.logscreen {
		
		$log-link-color:  darken(($color + 111), 23%);
		$btn-color: darken($color, 15%) - #1a0507;
		
			background-color:darken($color, 15%);
			.wrapper-log a{color:$log-link-color;
				&:hover{color: darken($log-link-color, 10%);}
			}
			
			.log-foot{ 
				.log-foot-text{color:$log-link-color;
					a{color:lighten($color, 100%);
						&:hover{color:lighten($color,30%);}
					}
				}
			}
			.log-footer{border-color: darken($color, 20%);
				ul{border-color: darken($color, 10%);
					li{ color:$log-link-color;
					a{color:$log-link-color;}
					}
				}
			}
			
				.btn-primary { position:relative; min-height:50px;
					@include button-variant(#fff, $btn-color, darken($btn-color, 5%));
					&:hover, &:active, &:focus	{@include button-variant(#fff, lighten($btn-color, 11%), lighten($btn-color, 8%));}
				}
		
			.floated-group::after{background-color:darken($color, 20%);}
			
		}


		
   } 

   
   
/*___BLUE LIGHT TMEHE ____*/ 
 
 
 
 
   @if $name == 'blue_light'{

	/*_LOGIN _*/


		
   } 

   
   
/*___GREEN LEAF TMEHE ____*/

   @else if $name == 'green_leaf'{
	
	/*_LOGIN _*/
	&.logscreen {

		$log-link-color:lighten($color, 50%);
		$btn-color: darken($color, 5%);
		
		
			background-color:$color;
			.wrapper-log a{color:$log-link-color;
				&:hover{color: darken($log-link-color, 10%);}
				
				&.text-link{color:lighten($color, 20%);}
		
			}
			
			.log-foot{  
				.log-foot-text{color:$log-link-color;
					a{color:lighten($color, 100%);
						&:hover{color:lighten($color,30%);}
					}
				}
			}
			.log-footer{border-color: lighten($color, 2%);
				ul{border-color: darken($color, 3%);
					li{ color:$log-link-color;
					a{color:$log-link-color;}
					}
				}
			}
			
				.btn-primary { position:relative; min-height:50px;
					@include button-variant(#fff, $btn-color, darken($btn-color, 5%));
					&:hover, &:active, &:focus{@include button-variant(#fff, lighten($btn-color, 11%), lighten($btn-color, 8%));}
				}
		
			.floated-group::after{background-color:darken($color, 5%);}
			.form-sign-up-steps .card.invitation-form {
				.card-header{background-color:darken($color, 5%); border-color:darken($color, 6%);}
				.is-completed .floated-label{color:$color; }
			}
		}
		

	 
			
   }


/*___GREEN DARK TMEHE ____*/

   @else if $name == 'green_dark'{
	
	/*_LOGIN _*/

						
   }
   
      
/*___ ROYAL BLUE TMEHE____*/

   @else if $name == 'royal_blue'{
						
   }
   
   
/*___ NAVY BLUE TMEHE____*/

   @else if $name == 'navy_blue'{
	
	/*_LOGIN _*/
   
   		$log-link-color:lighten($color, 50%);
			$btn-color: darken($color, 1%);
			
	   &.logscreen {background-color:darken($color, 6%);
	   
		  .wrapper-log a{color:$log-link-color;
				&:hover{color: darken($log-link-color, 10%);}
		   }
	
			.log-foot{ 
				.log-foot-text{color:$log-link-color;
					a{color:lighten($color, 100%);
						&:hover{color:lighten($color,30%);}
					}
				}
			}
			
			.log-footer{border-color:darken($color, 12%);
				ul{border-color:darken($color, 0%);
					li{ color:$log-link-color;
						a{color:$log-link-color;}
					}
				}
			}
		  
		  .btn-primary { position:relative; min-height:50px;
				@include button-variant(#fff, $btn-color, darken($btn-color, 12%));
				&:hover, &:active, &:focus{@include button-variant(#fff, lighten($btn-color, 11%), lighten($btn-color, 5%));}
			}

	   
	   }

	 
	  
   }
   
   
/*___ PURPLE TMEHE____*/

   @else if $name == 'purple_dark'{
   
			
						
   }
   
   
/*___ RED LIGHT TMEHE____*/ 

   @else if $name == 'red_light'{
	
	/*_LOGIN _*/
	&.logscreen {

		$log-link-color:lighten($color, 75%);
		$btn-color: darken($color, 5%);
		
		
			background-color:$color;
			.wrapper-log a{color:$log-link-color;
				&:hover{color: darken($log-link-color, 10%);}
				
				&.text-link{color:lighten($color, 20%);}
		
			}
			
			.log-foot{  
				.log-foot-text{color:$log-link-color;
					a{color:lighten($color, 100%);
						&:hover{color:lighten($color,65%);}
					}
				}
			}
			.log-footer{border-color: darken($color, 3%);
				ul{border-color: darken($color, 4%);
					li{ color:$log-link-color;
					a{color:$log-link-color;}
					}
				}
			}
			
				.btn-primary { position:relative; min-height:50px;
					@include button-variant(#fff, $btn-color, darken($btn-color, 5%));
					&:hover, &:active, &:focus{@include button-variant(#fff, lighten($btn-color, 11%), lighten($btn-color, 8%));}
				}
		
			.floated-group::after{background-color:darken($color, 20%);}
			.form-sign-up-steps .card.invitation-form {
				.card-header{background-color:darken($color, 16%); border-color:darken($color, 19%);}
				.is-completed .floated-label{color:$color; }
			}
		}
					
   }
   
   
/*___ RED DARK TMEHE____*/ 

   @else if $name == 'red_dark'{
   
	/*_LOGIN _*/
   
$log-link-color:lighten($color, 40%);
			
	   &.logscreen {
	   
		  .wrapper-log a{color:$log-link-color;
				&:hover{color: darken($log-link-color, 10%);}
		   }
	
			.log-foot{ 
				.log-foot-text{color:$log-link-color;
					a{color:lighten($color, 100%);
						&:hover{color:lighten($color,30%);}
					}
				}
			}
			
			.log-footer{
				ul{
					li{ color:$log-link-color;
						a{color:$log-link-color;}
					}
				}
			}
			
		}   
   

						
   }
   
   
/*___ RED NIGHT TMEHE____*/ 

   @else if $name == 'red_night'{
   
    /*_LOGIN _*/
   
   		$log-link-color:lighten($color, 50%);
			$btn-color: darken($color, 1%);
			
	   &.logscreen {background-color:darken($color, 5%);
	   
		  .wrapper-log a{color:$log-link-color;
				&:hover{color: darken($log-link-color, 10%);}
		   }
	
			.log-foot{ 
				.log-foot-text{color:$log-link-color;
					a{color:lighten($color, 100%);
						&:hover{color:lighten($color,30%);}
					}
				}
			}
			
			.log-footer{border-color:darken($color, 8%);
				ul{border-color:lighten($color, 1%);
					li{ color:$log-link-color;
						a{color:$log-link-color;}
					}
				}
			}
		  
		  .btn-primary { position:relative; min-height:50px;
				@include button-variant(#fff, $btn-color, darken($btn-color, 8%));
				&:hover, &:active, &:focus{@include button-variant(#fff, lighten($btn-color, 11%), lighten($btn-color, 5%));}
			}

	   
	   }
						
   }
   
   
/*___ ORANGE LIGHT TMEHE____*/ 

   @else if $name == 'orange_light'{

	/*_LOGIN _*/
   		$log-link-color:lighten($color, 35%);
		$btn-color: darken($color, 6%);
		
  	   &.logscreen {background-color:$color;
	   
	   
	   .wrapper-log a{color:$log-link-color;
				&:hover{color: darken($log-link-color, 10%);}
		   }
	
			.log-foot{ 
				.log-foot-text{color:$log-link-color;
					a{color:lighten($color, 100%);
						&:hover{color:lighten($color,30%);}
					}
				}
			}
			
			.log-footer{border-color:darken($color, 8%);
				ul{border-color:lighten($color, 5%);
					li{ color:$log-link-color;
						a{color:$log-link-color;}
					}
				}
			}
		  
		  .btn-primary { position:relative; min-height:50px;
				@include button-variant(#fff, $btn-color, darken($btn-color, 10%));
				&:hover, &:active, &:focus{@include button-variant(#fff, lighten($btn-color, 11%), lighten($btn-color, 5%));}
			}
	   


		
	   
	   
	   }
   }


/*___ GOLDEN BROWN TMEHE____*/ 

   @else if $name == 'golden_brown'{
   
   	/*_LOGIN _*/

   
	
						
   }
   
   
/*___ DARK BROWN TMEHE____*/ 

   @else if $name == 'dark_brown'{
     .text-link{color:lighten($color, 20%);}

   
   }
   
   
/*___ GRAY TMEHE____*/ 

   @else if $name == 'gray_dark'{
   
	/*_Table _*/
   
   
	
					
   }
   
   
/*___ DARK TMEHE____*/ 

  @else if $name == 'dark_night'{
  
	/*_LOGIN _*/
   &.logscreen {background-color:darken($color, 14%);}

						
   }

   @else {
   
	   
	    
   }

/*__________________END CONDITION FOR THEME DESIGN______________*/

 
 
  
/*__________________END THEME NAME MAIN SECTION______________*/
  }
/*__________________________________________________________________________________________________________________________________________________________________________________
  
START COLOR THEME BUTTON 
__________________________________________________________________________________________________________________________________________________________*/
 
  [data-theme='#{$name}'] {
    background: $color;
    
    &:hover, &.active {
      background: darken($color, 10%);

    }
    
    &[disabled] {
      background: desaturate($color, 40%);

    }
  }
  
/*__________________END BUTTON ______________*/
}

// Including themes 
// By looping through the $themes map
@each $key, $value in $themes {
  @include themeouter($key, $value);
}
