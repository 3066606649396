//AdminLTE 2 Variables.less
//=========================
//PATHS
//--------------------------------------------------------
//$boxed-layout-bg-image-path: "../img/boxed-bg.jpg";
//COLORS
//--------------------------------------------------------

//Primary
$light-blue: #3c8dbc;
//Danger
$red: #dd4b39;
//Success
$green: #00a65a;
$light-green: #7ad67a;
$dark-green :green;

//Info
$aqua: #00c0ef;
//Warning
$yellow: #ffba10;
$blue: #0073b7;
$navy: #001F3F;
$teal: #39CCCC;
$olive: #3D9970;
$lime: #01FF70;
$orange: #FF851B;
$fuchsia: #F012BE;
$purple: #605ca8;
$maroon: #D81B60;
$black: #111;
$graylight :#b3b3b3;
$gray-dark :#373a3c;
$white: #fff;
$themeColor: #3c988f;
$basic:#a3afa9;
$silver:#8ec15d;
$gold:#e28140;
$platinum:#4596c9;

$btn-color: $themeColor - #1a0507;

$font-family: 'robotoregular', sans-serif;
$font-family-italic: 'robotoitalic', sans-serif;
$font-family-medium: 'robotomedium', sans-serif;
$font-family-medium-italic: 'robotomedium_italic', sans-serif;
$font-family-light: 'robotolight', sans-serif;
$font-family-light-italic: 'robotolight_italic', sans-serif;
$font-family-bold: 'robotobold', sans-serif;
$font-family-bold-italic: 'robotobold_italic', sans-serif;


$body-font-size : 0.8125rem;
$body-color: lighten($gray-dark, 25%);

$red: red;
$green: green;
$blue: blue;
$yellow: yellow;

$text-red: $red;
$text-light-green: #7ad67a;
$text-green : #5cb85c;
$text-dark-green :green;
$text-blue: #459bf2;
$text-light-blue: $light-blue;
$text-yellow: $yellow;
$text-aqua: $aqua;
$text-navy: $navy;
$text-teal: $teal;
$text-olive: $olive;
$text-lime: $lime;
$text-orange: $orange;
$text-fuchsia: $fuchsia;
$text-purple: $purple;
$text-maroon: $maroon; 

$text-black: $black;
$text-graylight :#b3b3b3;
$text-white: #fff;
$text-info: #459bf2;
$text-success: #5cb85c;
$text-danger: #d9534f;
$text-muted :#818a91;



//LAYOUT
//--------------------------------------------------------
//Side bar and logo width
$sidebar-width: 130px;


$link-color : lighten($themeColor, 20%);
$link-hover-color: lighten($link-color, 15%);
$log-link-color : darken(($themeColor + 111), 8%);

//Body background (Affects main content background only)
$body-bg: #ecf0f5;

//SIDEBAR SKINS
//--------------------------------------------------------

//Light sidebar
$sidebar-light-bg: #333;

//CONTROL SIDEBAR
//--------------------------------------------------------
$control-sidebar-width: $sidebar-width;
//BOXES
//--------------------------------------------------------
$box-border-color: #f4f4f4;
$box-border-radius: 3px;
$box-footer-bg: #fff;
$box-boxshadow: 0 0 5px rgba(0, 0, 0, 0.1);
$box-padding: 10px;
//Box variants
$box-default-border-top-color: #d2d6de;
//BUTTONS
//--------------------------------------------------------
$btn-boxshadow: none;

//FORMS
//--------------------------------------------------------
$input-radius: 0;
//BUTTONS
//--------------------------------------------------------
//Border radius for non flat buttons
$btn-border-radius: 3px;
//CHAT WIDGET
//--------------------------------------------------------
$attachment-border-radius: 3px;
//TRANSITIONS SETTINGS
//--------------------------------------------------------
//Transition global options
$transition-speed: .3s;
$transition-fn: ease-in-out;
//cubic-bezier(0.32,1.25,0.375,1.15);

// TABLE 
//---------------------------------------------------
$tr-success: #eee;
/*#cdece9;*/

$tr-top-border-color:#e5e5e5;

// TOOLTIP
//---------------------------------------------------
$tooltip-bg : #777;
/*$tooltip-arrow-color:         lighten($tooltip-bg, 5%) !default;
*/

//CARD
//---------------------------------------
$card-header-border-bottom-color: $themeColor;

//pagination
//---------------------------------------------
$pagination-padding:3px 8px;
$pagination-padding-active:2px 8px;
$progress-border-radius : 20rem !default;

//Status Button use for task page
$kanban-approval :#ff8082;
$kanban-new :#ff7f83;
$kanban-closed :#4bbb4b;
$kanban-progress :#00c0ef;
$kanban-resolved :#83e183;
$kanban-hold :#b3b3b3;
$kanban-pending :#e68989;
$kanban-audited :#4de8d4;


//--Dashboard Boxes
$box-blue :#d7e8eb;
$box-lightgreen :#e5eecc;
$box-purple :#e4d5f0;
$box-green :#d6e8d6;
$box-orange :#f5ead5;
$box-red :#f6d4cc;

//--Help Desk
$take_a_tour: #4f8ab7;

$helpWidth:600px;